import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { HttpService } from './http.service';
import { type IConfig } from '../models/config';

declare global {
  interface Window {
    apiBuildVersion: string;
    buildVersion: string;
  }
}

@Injectable()
export class ConfigService {
  private readonly http = inject(HttpClient);
  private readonly httpService = inject(HttpService);
  static settings: IConfig;

  public async load(): Promise<void> {
    const file = `configs/config.json?v=${uuidv4()}`;
    await this.http
      .get(file)
      .toPromise()
      .then((response: IConfig) => {
        ConfigService.settings = response;
        window.buildVersion = this.getBuildVersion();
        this.getApiBuildVersion();
      })
      .catch(() => {
        console.error(`Could not load configuration file '${file}'`);
      });
  }

  getBuildVersion(): string {
    return ConfigService.settings.buildVersion;
  }

  async getApiBuildVersion(): Promise<any> {
    await this.httpService.get('api/system/version').then((res) => {
      window.apiBuildVersion = res;
    });
  }
}
